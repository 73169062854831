@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap');

body {
  font-family: 'Anton', sans-serif;
  background-color: #ffffff;
}

br {
  content: '';
  margin: 1em 0;
  display: block;
}

/* Add the handwriting font */
.font-handwriting {
  font-family: 'Patrick Hand', cursive, 'Comic Sans MS', sans-serif;
}
